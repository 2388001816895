<template>
  <div>
    <div class="float_right">
      <p><a class="link" @click="()=>{
            contentHtml = textInfo.rule
            contentHtmlModal = true
          }">活动规则</a></p>
      <p><router-link to="/service" class="link">联系客服</router-link></p>
      <p><router-link to="/refund" class="link" v-if="is_open_refund">极速退款</router-link></p>
    </div>

    <div class="container">
      <div class="header">
        <div class="t1">锁定百元话费优惠券包</div>

        <div class="t2">
          <van-count-down millisecond :time="time">
            <template #default="timeData" >
              <span class="colon">支付剩余时间</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">:</span>
              <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
            </template>
          </van-count-down>
        </div>
      </div>

      <div class="main box" id="phone">
        <img src="https://ntyz.oss-cn-shanghai.aliyuncs.com/images/yidong.png" style="width: 30%" alt="">
        <img src="https://ntyz.oss-cn-shanghai.aliyuncs.com/images/dainx.png" style="width: 30%" alt="">
        <img src="https://ntyz.oss-cn-shanghai.aliyuncs.com/images/liantong.png" style="width: 30%" alt="">
        <div class="mobile">
          <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入充值手机号码">
            <template #right-icon>
              <van-icon class-prefix="icon" name="dianhuaben" size="30" color="#3b82fb"/>
            </template>
          </van-field>
        </div>

        <div class="jsje">待节省金额：<span>{{money.total_price - money.price | keepToInt}}元</span></div>

        <van-row class="moneyList" :gutter="10">
          <van-col span="8" v-for="(item,key) in moneyList" :key="key" @click="onChangeMoney(item)">
            <div :class="['item',money.price==item.price?'act':'']">
              <div class="price"><span>{{item.total_price}}</span>元</div>
              <div class="real_price">会员价{{item.price}}元</div>
              <img v-if="item.status" :src="oss+'tj.png'" class="icon"/>
            </div>
          </van-col>
        </van-row>

        <div class="bottom_title" style="display: none;"><van-icon class-prefix="icon" name="chongzhi" size="20" color="#e2dd55"/> 全国三网通用，领不到可退</div>

        <van-divider />
        <div class="lm">支付方式</div>
        <div class="payList">
          <van-radio-group v-model="json.pay_way">
            <van-cell-group :border="false">
              <van-cell v-for="item in payList" :key="item.key">
                <template #icon><img :src="item.img" height="20"/></template>
                <template #title>
                  <van-row type="flex" align="center" :gutter="2">
                    <van-col>{{item.name}}</van-col>
                    <van-col><van-tag plain type="primary" color="#e8323c" text-color="#e8323c">首单随机立减，最高至免单</van-tag></van-col>
                  </van-row>
                </template>
                <template #right-icon>
                  <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>

        <div class="paymoney">
          <p>实际支付付金额：<span class="font-color-blue">{{money.price}}元</span></p>
          <p>实际到账话费优惠券包：<span class="font-color-blue">{{money.total_price}}元</span></p>
        </div>
        <div class="zhifu-bottom">
					<div class="zhifu-show">
						会员价: ￥<span class="payAmount">{{money.price}}</span>
					</div>
					<div class="zhifu-btn no-copy">
						立即支付
					</div>
					<div id="zhifu-btn"  @click="onsubmit" style="width: 200px;height: 100px;background: none;position: absolute;right: 0;">
					</div>
				</div>
        <!-- <div class="paybtn"><van-button type="info" round block @click="onsubmit">立即支付</van-button><span class="js">节省近{{money.total_price - money.price | keepToInt}}元</span></div> -->
        <div class="tips">
          <span><van-checkbox v-model="isread" icon-size="16px">提交即您已阅读并同意</van-checkbox></span>
          <a class="font-color-blue" @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《隐私协议》</a><a class="font-color-blue" @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>

        </div>

      </div>

      <div class="box mt10" v-if="is_show_rule">
        <div class="lm t-c">话费优惠券包说明</div>
        <div class="content mt15" v-html="detail.detail"></div>

        <div class="font-color-gray t-c mt15">
          <p>客服电话：4006178158</p>
          <p>{{textInfo.company_name}}</p>
        </div>
      </div>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#2d8cf0" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

    <van-popup v-if="AdvPopModal" v-model="AdvPopModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="AdvPop" closeable>
      <div class="t1">恭喜您</div>
      <div class="t2">获得<span>限时降价</span>购买资格！</div>
      <div class="adv">¥ <span>{{money.price}}</span> <s>¥ {{money.total_price}}</s></div>
      <div class="text">
        <van-count-down millisecond :time="60000">
          <template #default="timeData" >
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">.</span>
            <span class="block">{{moment(timeData.milliseconds).format("S")}}</span>
            <span class="s">秒后收回资格，欲购从速</span>
          </template>
        </van-count-down>
      </div>
      <div class="btn">
        <van-button round block @click="onsubmit1">{{money.price}}元立即抢购</van-button>
        <span class="tips">不回本可退款</span>
      </div>

      <div class="font-color-gray">你的购买价格击败了98.82%的人</div>
    </van-popup>

    <nopay v-if="RetentionModal" :visible="RetentionModal" :money="money" @close="()=>{
      this.clearTime()
      this.RetentionModal = false
    }" @onsubmit1="()=>{
      this.getCreateOrder(json)
    }" @onsubmit2="()=>{
      this.finishPay(json.order_sn)
    }"/>
  </div>
</template>
<script>
  import moment from 'moment'
  import {isWeixin,keepToInt,getordernum} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'

  import nopay from './components/nopay'

  import {GetPayConfig, GetUserPrivate,CreateOrder, GetOrderStatus} from './services/services'
  export default {
    name:'pay100_1',
    components: {nopay},
    data () {
      return {
        show:false,
        textInfo:{},
        contentHtml:'',
        time:1000*60*3,
        detail:{},
        moneyList:[],
        payList:[],
        money:{},
        json:{
          pay_way:0,
          order_sn:'',
          phone:'',
          url:location.href
        },
        create_status:0,
        paysuccess:0,
        paytime:3 * 1000,
        isWeixin:false,
        count:0,
        timer:null,
        isread:true,
        contentHtmlModal:false,
        type:'',
        is_open_refund:false,
        AdvPopModal:false,
        RetentionModal:false,//挽留
        is_show_rule:true
      }
    },
    created(){
      const {phone} = this.$route.query
      let getPhone = '';
      if(phone == undefined){
        console.log(phone,'123');
        getPhone = ''
      }else{
        if (!this.isBase64(phone)) {
          getPhone = phone
        }else{
          getPhone = atob(phone)
        }
      }
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:getPhone
      }
      if(this.$route.query.phone){

        // this.$nextTick(()=>{
        //   let toElement = document.getElementById('phone')
        //   toElement.scrollIntoView()
        // })
      }

      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        if(this.json.openid == ''){
          this.getOpenId()
        }
        this.json.pay_way = 14
      }
      this.type = this.json.type==undefined ? undefined:this.json.type

      setTimeout(()=>{
        this.AdvPopModal = true
      },1000*30)
    },
    filters:{
      keepToInt
    },
    computed:{
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }

        this.findOrderStatus(this.json.order_sn)
      }
      if(this.json.order_sn){
        this.findOrderStatus(this.json.order_sn)
      }
      this.findpayConfig()
      this.finduserprivate()
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    methods:{
      moment,
      initCloud,
      getOpenId(){
        const redirect_url = location.href
        window.location.href = 'https://pay2.senhuo.cn/pay/WechatOpenId.php?redirect_url=' + encodeURIComponent(redirect_url)
      },
      isBase64(str){
        if(str === '' || str.trim() === ''){
            return false;
        }
        try{
            return btoa(atob(str)) == str;
        }catch(err){
            return false;
        }
      },
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            const {goodsArray,payConfig,is_show_rule} = result.data.data
            this.is_show_rule = is_show_rule == undefined ?true:is_show_rule
            this.detail = result.data.data
            this.moneyList=goodsArray
            this.payList=payConfig
            this.json.pay_way=this.payList[0].payWay
            let ValidList=goodsArray.filter(item=>item.status == 1)
            if(ValidList.length){
              this.money = ValidList[0]
            }
            this.is_open_refund = result.data.data.is_open_refund

            const {app_download_url,kefu_url} = this.detail
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onChangeMoney(record){
        if(record.status){
          this.money = record
        }
      },
      onsubmit1(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          this.AdvPopModal = false
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          this.AdvPopModal = false
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          this.AdvPopModal = false
          return false
        }
        this.onsubmit()
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.pay_way)
        const {pwyAccount} = pay
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        let json={
          ...this.json,
          id:this.money.id,
          account:pwyAccount,
          order_sn:order_sn,
          type:this.type
        }
        this.getCreateOrder(json)
      },
      getCreateOrder(res){
        CreateOrder({...res,openid:(this.isWeixin?this.json.openid:undefined),pay_tag:((this.isWeixin || res.pay_way==14)?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let pay_way = res.pay_way
            if(pay_way==14){
              location.href=data
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(data)
            }

          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        document.forms["pay"].setAttribute("target", "_self")
        document.forms["pay"].submit()
        div.remove()
      },
      findOrderStatus(order_sn){
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            clearInterval(this.timer)
            this.$router.push({path:'/success'})
          }else{
            if(!this.RetentionModal){
              this.RetentionModal = true
            }
            // this.$dialog.confirm({
            //   title: '确认支付情况',
            //   confirmButtonText:'已完成支付',
            //   cancelButtonText:'重新支付',
            //   closeOnClickOverlay:true
            // }).then(() => {
            //   this.finishPay(order_sn)
            // }).catch((res) => {
            //   if(res == 'overlay'){
            //     this.clearTime()
            //   }else{
            //     this.getCreateOrder(this.json)
            //   }
            // })
            if(this.timer == null){
              this.getTimerStatus(order_sn)
            }

          }
        })
      },
      getTimerStatus(order_sn){
        this.timer = setInterval(() => {
          if(this.count>=5){
            // this.create_status=1
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus(order_sn)
        },3*1000)
      },
      finishPay(order_sn){//支付完成
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            this.clearTime()
            this.$router.push({path:'/success'})
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
        // location.href = 'https://api.youzuanmy.vip/xd/paysuccess.html?orderNo='+this.json.order_sn
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.finishPay()
            break;
          case 0:
            this.getCreateOrder(this.json)
            break;
        }
      }
    }
  }

</script>
<style lang="less" scoped>
  .container{background:url('@{static-dir}bjt2.png') no-repeat;background-size:100% auto;padding:0px 10px;
    .header{text-align:center;padding:20px 0px 12px 0px;color:#fff;
      .t1{font-size:30px;font-weight:700;}
      .t2{margin-top:10px;
        .colon{line-height:20px;color:#fff;padding:0px 5px;font-size:18px;}
        .block{display:inline-block;color:#1e52fa;text-align: center;width:36px;background:linear-gradient(180deg, #fffffe 0%, #fef3b9 100%);border-radius:2px;font-weight:700;font-size:18px;padding:6px 0px;}
      }
    }
    .main{min-height:calc(100vh - 350px);}
    .box{background:#fff;border-radius:6px;padding:14px;
      .lm{font-size:16px;font-weight:700;height:30px;line-height:30px;}
      .content{color:#656565;
        /deep/p{padding:2px 0px;line-height:22px;}
      }
    }
    .moneyList{margin-top:15px;
      .item{background:#f3f9fe;text-align:center;border-radius:6px;padding:16px 0px;position:relative;
        &.act{background:#3b82fb;
          .price,.real_price{color:#fff;}
        }
        .price{font-size:18px;color:#888;
          span{font-size:30px;}
        }
        .real_price{color:#A7ACB0;font-size:12px;margin-top:6px;}
        .icon{position:absolute;left:0px;top:0px;z-index:1;width:36px;}
      }
    }

    .mobile{border-bottom:1px solid #f1f1f1;
      .van-cell{padding:5px 0px 10px 0px;
        /deep/.van-field__control{font-size:20px;}
      }
    }
    .jsje{background:#f1f9ff;padding:12px 12px;font-weight:700;font-size:18px;margin-top:10px;color:#555;
      span{color:#1e52fd;}
    }
    .bottom_title{font-size:18px;color:#888;margin-top:20px;}
    .payList{
      .van-cell{padding:10px 0px;align-items:center;
        .van-cell__title{margin-left:5px;}
      }
    }
    .paymoney{font-size:14px;color:#666;margin-top:6px;
      p{line-height:36px;font-weight:700;font-size:16px;}
    }
    .zhifu-bottom {
    background: url(https://ntyz.oss-cn-shanghai.aliyuncs.com/images/zhifu-bg.png) no-repeat center top;
    background-size: 100% auto;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    background-color: #0D0D0D;
    font-size: 0.6rem;
    color: #fff;
    height: 1.2rem;
    align-items: center;
    .zhifu-show {
      font-size: 0.3rem;
      color: #c9ab17;
       span {
          font-size: 0.5rem;
          color: #c9ab17;
          font-weight: 500;
      }
  }
  .zhifu-btn {
    font-size: 0.48rem;
    color: #fff;
    font-weight: bold;
    -webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;
}
}
    .paybtn{margin:20px auto 10px;width:95%;position:relative;
      .van-button{-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;
        .van-button__text{font-size:20px;}
      }
      .js{position:absolute;background:#e60309;color:#fff;right:10px;top:-12px;padding:1px 15px 2px 15px;border-radius:50px 50px 50px 0px;font-size:12px;}
    }
    .tips{padding:10px 0px;
      span{display:inline-block;}
      .van-checkbox__label{font-size:12px;color:#aaa;}

    }
  }
  @keyframes paybtn_scalc{
    0% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
    25% {
      -webkit-transform:scale(1.05);
      transform: scale(1.05);
    }
    50% {
      -webkit-transform:scale(1.02);
      transform: scale(1.02);
    }
    75% {
      -webkit-transform:scale(1.05);
      transform: scale(1.05);
    }
    100% {
      -webkit-transform:scale(1);
      transform: scale(1);
    }
  }
  .AdvPop{width:76%;border-radius:6px;padding:25px 6%;text-align:center;
    .t1{font-size:24px;font-weight:700;}
    .t2{margin-top:10px;
      span{color:#FF4E33;}
    }
    .adv{width:150px;background:url('@{static-dir}timed-specials.png') no-repeat;background-size:100% auto;height:76px;padding-top:40px;margin:20px auto 0px;color:#999;text-align:left;padding-left:100px;
      span{color:#000;font-size:32px;}
      s{margin-left:5px;}
    }
    .text{
      .van-count-down{font-size:24px;color:#666;
        .s{font-size:14px;margin-left:8px;}
      }
    }
    .btn{position:relative;margin:46px auto 15px;
      .van-button{-webkit-animation:paybtn_scalc 1s ease infinite;animation:paybtn_scalc 1s ease infinite;background:linear-gradient(to right,#feab37,#fd7d2e);color:#fff;
        .van-button__text{font-size:20px;}
      }

      .tips{position:absolute;background:#ff4e32;color:#fff;right:0px;top:-34px;padding:5px 15px 6px 15px;border-radius:4px;font-size:12px;
        &::after{content:'';height:8px;width:8px;transform:rotate(225deg);background:#ff4e32;position:absolute;right:50px;bottom:-4px;z-index:1;}
      }
    }

  }
</style>
